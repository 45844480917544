import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import './contact.scss'

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div className='contact'>
      <h1>Contact</h1>
      <p>Get in contact with me at <a href='https://twitter.com/akanewz'>Twitter</a></p>
    </div>
    <div className='back-to-home'><Link to='/'>Home</Link></div>
  </Layout>
)

export default Contact
